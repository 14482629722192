import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url, media_url } from "../constants";
import { IoMdClose } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import Skeleton from "react-loading-skeleton";
import { PiArrowBendDownRightLight } from "react-icons/pi";

const Portofolio = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noItems, setNoItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [isHelpModal, setIsHelpModal] = useState(false);
  const [isHelpModalClosing, setIsHelpModalClosing] = useState(false);

  useEffect(() => {
    axios
      .get(`${api_url}/portofolio/`)
      .then((response) => {
        setItems(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setNoItems(true);
      });
  }, []);

  useEffect(() => {
    const isHelpModalFirstTime =
      localStorage.getItem("isHelpModalFirstTime") !== "false";
    if (isHelpModalFirstTime) {
      const timer = setTimeout(() => {
        setIsHelpModal(true);
      }, 1000);
      localStorage.setItem("isHelpModalFirstTime", true);
      return () => clearTimeout(timer);
    }
  }, [selectedItem]);

  const CloseModal = useCallback(() => {
    setIsModalClosing(true);
    localStorage.setItem("isHelpModalFirstTime", false);
    setTimeout(() => {
      setIsModalClosing(false);
      setSelectedItem(null);
    }, 300);
  }, []);

  const CloseHelpModal = useCallback(() => {
    setIsHelpModalClosing(true);
    localStorage.setItem("isHelpModalFirstTime", false);
    setTimeout(() => {
      setIsHelpModalClosing(false);
      setIsHelpModal(false);
    }, 300);
  }, []);

  const renderSkeletons = useMemo(
    () =>
      Array.from({ length: 6 }).map((_, index) => (
        <Skeleton key={index} height={187} borderRadius={12} />
      )),
    []
  );

  return (
    <div className="px-80 mt-12 xl:px-48 lg:px-24 md:px-12 sm:px-8">
      <h1 className="text-3xl font-bold">Portofoliul nostru de lucrări</h1>
      <div className="grid grid-cols-3 gap-8 mt-2 md:grid-cols-2 sm:grid-cols-1">
        {isLoading ? (
          renderSkeletons
        ) : noItems ? (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="col-span-3 text-center bg-white px-8 py-4 text-lg"
          >
            Nu există lucrări de afișat
          </motion.p>
        ) : (
          items.map((item, index) => (
            <motion.button
              key={item.id}
              onClick={() => setSelectedItem(item)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: (index / 4) * 0.1 }}
              className="bg-white relative rounded-xl drop-shadow-md group shadow-lg overflow-hidden"
            >
              <img
                loading="lazy"
                src={media_url + item.images[0].image}
                alt={item.title}
                className="w-full h-auto object-cover"
              />
              <h2 className="text-xl font-semibold absolute h-0 bottom-0">
                {item.title}
              </h2>
            </motion.button>
          ))
        )}
      </div>
      {selectedItem && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isModalClosing ? 0 : 1 }}
            className="fixed top-0 left-0 w-full h-full bg-black/25"
            onClick={CloseModal}
          ></motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.9, x: "-50%", y: "-50%" }}
            animate={{
              opacity: isModalClosing ? 0 : 1,
              scale: isModalClosing ? 0.9 : 1,
            }}
            className="flex drop-shadow-xl flex-col bg-background rounded-lg w-1/2 md:w-4/5 sm:w-full lg:w-2/3 p-8 fixed top-1/2 left-1/2"
          >
            <button
              onClick={CloseModal}
              className="absolute top-4 w-fit right-4 p-1.5 bg-surface rounded-full"
            >
              <IoMdClose className={"text-2xl"} />
            </button>
            <h2 className="text-2xl font-bold">{selectedItem.title}</h2>
            <p className="mt-2">{selectedItem.description}</p>
            <div className="relative">
              <Carousel
                onSwipeStart={CloseHelpModal}
                useKeyboardArrows={true}
                onClickThumb={CloseHelpModal}
                showThumbs={false}
                className="mt-2"
              >
                {selectedItem.videos.map((video) => (
                  <div key={video.id}>
                    <video
                      src={media_url + video.video}
                      controls
                      className="w-full"
                    ></video>
                  </div>
                ))}
                {selectedItem.images.map((image) => (
                  <img
                    key={image.id}
                    src={media_url + image.image}
                    alt={selectedItem.title}
                    className="w-full object-cover"
                    loading="lazy"
                  />
                ))}
              </Carousel>
              {isHelpModal && (
                <>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHelpModalClosing ? 0 : 1 }}
                    className="absolute top-0 left-0 w-full h-full bg-black/35"
                    onClick={CloseHelpModal}
                  ></motion.div>
                  <motion.div
                    onClick={CloseHelpModal}
                    animate={{ opacity: isHelpModalClosing ? 0 : 1 }}
                    className="absolute right-12 flex items-end flex-col top-1/2 translate-y-[-66%]"
                  >
                    <motion.p
                      initial={{ opacity: 0, y: 24, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      className="bg-surface text-black rounded-md px-4 py-1 text-lg sm:text-xs md:text-base font-semibold"
                    >
                      Apasă pe săgeata de mai jos pentru a naviga.
                    </motion.p>
                    <motion.span
                      initial={{ opacity: 0, x: 0 }}
                      animate={{ opacity: 1, x: 24 }}
                      transition={{ duration: 0.3, delay: 0.1 }}
                    >
                      <PiArrowBendDownRightLight className="w-fit text-8xl md:text-7xl sm:text-6xl text-surface" />
                    </motion.span>
                  </motion.div>
                </>
              )}
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default Portofolio;
