import React, { useState, useMemo, useCallback } from "react";
import { motion } from "framer-motion";
import { faqs as faqsData } from "../constants";

const FrequentlyAskedQuestions = () => {
  const [openedQuestions, setOpenedQuestions] = useState([]);

  const faqs = useMemo(() => faqsData, []);

  const toggleQuestion = useCallback(
    (question) => {
      setOpenedQuestions((prev) =>
        prev.includes(question)
          ? prev.filter((q) => q !== question)
          : [...prev, question]
      );
    },
    [setOpenedQuestions]
  );

  return (
    <div className="px-80 mt-12 xl:px-48 lg:px-24 md:px-12 sm:px-8">
      <h1 className="text-3xl font-bold">Întrebări frecvente</h1>
      <p className="subtitle">
        În această secțiune veți găsi răspunsuri la cele mai frecvente întrebări
        pe care le primesc clienții noștri.
      </p>
      <div className="grid grid-cols-3 mt-2 md:grid-cols-2 sm:grid-cols-1">
        <motion.a
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          href="#website"
          className="py-2 text-center font-semibold border-r-2 sm:border-l-2 border-solid border-primary transition-colors ease-in-out duration-200 bg-secondary/15 hover:bg-secondary/20"
        >
          Întrebări despre site-uri web
        </motion.a>
        <motion.a
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2, delay: 0.1 }}
          href="#mobile"
          className="py-2 font-semibold text-center transition-colors sm:border-r-2 sm:border-l-2 border-primary border-solid ease-in-out duration-200 bg-secondary/15 hover:bg-secondary/20"
        >
          Întrebări despre aplicații mobile
        </motion.a>
        <motion.a
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2, delay: 0.2 }}
          href="#other"
          className="py-2 font-semibold text-center md:col-span-2 md:border-l-0 sm:border-r-2 border-l-2 border-solid border-primary transition-colors ease-in-out duration-200 bg-secondary/15 hover:bg-secondary/20"
        >
          Alte întrebări
        </motion.a>
      </div>
      <div className="flex flex-col gap-y-8 mt-8">
        {faqs.map((faq) => (
          <div key={faq.type}>
            <a id={faq.type} />
            <h2 className="text-2xl font-semibold pt-4 sm:text-xl">
              {faq.title}
            </h2>
            <motion.div
              initial={{ y: -30, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              className="flex flex-col gap-y-2"
            >
              {faq.questions.map((question) => (
                <button
                  onClick={() => toggleQuestion(question.question)}
                  key={question.question}
                  className="bg-white text-left p-4 rounded-xl"
                >
                  <h3 className="text-xl sm:text-lg font-semibold">
                    {question.question}
                  </h3>
                  {openedQuestions.includes(question.question) && (
                    <motion.p
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="overflow-hidden"
                    >
                      {question.answer}
                    </motion.p>
                  )}
                </button>
              ))}
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
