import { configureStore } from "@reduxjs/toolkit";
import modalsReducer from "./modalsSlice";
import globalMessageReducer from "./globalMessageSlice";

export const store = configureStore({
  reducer: {
    modals: modalsReducer,
    globalMessage: globalMessageReducer,
  },
});
