import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import UserLayout from "./screens/UserLayout";
import Portofolio from "./screens/Portofolio";
import Contact from "./screens/Contact";
import FrequentlyAskedQuestions from "./screens/FrequentlyAskedQuestions";
import GlobalMessage from "./components/GlobalMessage";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="" element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/portofoliu" element={<Portofolio />} />
          <Route path="/contacte" element={<Contact />} />
          <Route
            path="/intrebari-frecvente"
            element={<FrequentlyAskedQuestions />}
          />
        </Route>
      </Routes>
      <GlobalMessage />
    </div>
  );
}

export default App;
