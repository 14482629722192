import React, { useState, useCallback, useMemo } from "react";
import personPhone from "../static/images/person-phone.svg";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url, contactCards as cardsData } from "../constants";
import { useDispatch } from "react-redux";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import { FaCheck } from "react-icons/fa6";

const Contact = () => {
  const dispatch = useDispatch();

  // Memoizează constantă pentru a evita re-crearea la fiecare randare
  const contactCards = useMemo(() => cardsData, []);

  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const SendMessage = useCallback(
    async (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = {
        name: formData.get("name"),
        email: formData.get("email"),
        message: formData.get("message"),
      };

      if (!data.name || !data.email || !data.message) {
        dispatch(setGlobalErrorMessage("Completați toate câmpurile"));
        return;
      }

      setIsMessageSending(true);
      try {
        await axios.post(`${api_url}/contact/`, data);
        setIsMessageSent(true);
      } catch (error) {
        dispatch(
          setGlobalErrorMessage("A apărut o eroare. Încercați din nou.")
        );
      } finally {
        setIsMessageSending(false);
      }
    },
    [dispatch]
  );

  return (
    <div className="px-80 mt-12 xl:px-48 lg:px-24 md:px-12 sm:px-8">
      <h1 className="text-3xl font-bold">Contactele noastre</h1>
      <p className="subtitle">
        Pentru orice întrebări sau nelămuriri, nu ezitați să ne contactați.
      </p>
      <div className="flex md:flex-col sm:flex-col gap-y-8">
        <div className="w-1/2 md:w-full sm:w-full">
          <div className="grid gap-x-6 gap-y-4 md:grid-cols-2 sm:grid-cols-1 grid-cols-2 mt-2 lg:grid-cols-1">
            {contactCards.map((card, index) => (
              <motion.div
                key={card.title}
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                className={`flex gap-x-4 bg-white items-center drop-shadow-md px-4 py-4 rounded-xl ${
                  index === contactCards.length - 1
                    ? "lg:col-span-1 col-span-2 sm:col-span-1"
                    : ""
                }`}
              >
                <div className="bg-surface h-fit p-2 rounded-full">
                  {card.icon}
                </div>
                <div className="flex flex-col">
                  <h2 className="text-xl font-semibold">{card.title}</h2>
                  <p>{card.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
          <h2 className="text-2xl mt-8 font-bold">
            Trimiteți-ne un mesaj. Vă vom răspunde rapid.
          </h2>

          {isMessageSent ? (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
              className="flex gap-x-4 bg-white sm:flex-col gap-y-2 items-center drop-shadow-md px-4 py-4 rounded-xl"
            >
              <div className="bg-surface h-fit p-2 rounded-full">
                <FaCheck className="text-3xl text-primary" />
              </div>
              <div className="flex flex-col">
                <h2 className="text-xl font-semibold">Mesaj trimis</h2>
                <p>
                  Mesajul a fost trimis cu succes. Vă vom contacta în cel mai
                  scurt timp posibil.
                </p>
              </div>
            </motion.div>
          ) : (
            <form className="mt-2" onSubmit={SendMessage}>
              <div className="flex flex-col gap-y-2">
                <input
                  disabled={isMessageSending || isMessageSent}
                  type="text"
                  placeholder="Nume"
                  required
                  name="name"
                />
                <input
                  disabled={isMessageSending || isMessageSent}
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  required
                />
                <textarea
                  disabled={isMessageSending || isMessageSent}
                  name="message"
                  placeholder="Mesaj"
                  className="h-40"
                  required
                ></textarea>
                <motion.button
                  disabled={isMessageSending || isMessageSent}
                  whileHover={{ scale: 1.025 }}
                  whileTap={{ scale: 0.975 }}
                  initial={{ opacity: 0, x: -30 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="py-2 px-8 bg-primary hover:bg-primaryAccent mt-2 text-lg font-bold transition-colors duration-200 text-white rounded-xl"
                >
                  {isMessageSending ? "Se trimite..." : "Trimiteți mesajul"}
                </motion.button>
              </div>
            </form>
          )}
        </div>
        <motion.img
          initial={{ x: 30, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          src={personPhone}
          alt="Person with phone"
          className="w-1/2 md:mx-auto sm:w-full -translate-y-6"
        />
      </div>
    </div>
  );
};

export default Contact;