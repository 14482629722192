import React, { useState, useCallback } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { openRequestModal, closeRequestModal } from "../redux/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import application from "../static/images/application.svg";
import website from "../static/images/website.svg";
import axios from "axios";
import { api_url } from "../constants";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";

const UserLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const modals = useSelector((state) => state.modals);

  const [isModalClosing, setIsModalClosing] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [isConsultationSending, setIsConsultationSending] = useState(false);
  const [isConsultationSent, setIsConsultationSent] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navLinks = [
    { title: "Portofoliu", url: "/portofoliu" },
    { title: "Întrebări frecvente", url: "/intrebari-frecvente" },
    { title: "Contacte", url: "/contacte" },
  ];

  const closeModal = useCallback(() => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      setSelectedService(null);
      setIsConsultationSent(false);
      dispatch(closeRequestModal());
    }, 300);
  }, [dispatch]);

  const sendConsultation = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = {
        name: formData.get("name"),
        phone_or_email: formData.get("phone_or_email"),
        service: selectedService,
      };

      if (!data.name || !data.phone_or_email || !selectedService) {
        dispatch(setGlobalErrorMessage("Completați toate câmpurile"));
        return;
      }

      setIsConsultationSending(true);

      axios
        .post(`${api_url}/consultation/`, data)
        .then(() => {
          setIsConsultationSending(false);
          setIsConsultationSent(true);
          dispatch(setGlobalErrorMessage("Cererea a fost trimisă cu succes!"));
        })
        .catch(() => {
          dispatch(
            setGlobalErrorMessage("A apărut o eroare. Încercați din nou.")
          );
          setIsConsultationSending(false);
        });
    },
    [dispatch, selectedService]
  );

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <nav className="flex md:flex-wrap gap-x-10 gap-y-6 items-center justify-between lg:gap-x-8 xl:px-48 lg:px-24 md:px-12 sm:px-8 px-80 pt-8 pb-4">
        <Link to="/" className="md:block md:w-full">
          <header className="text-5xl font-bold md:mx-auto w-fit">
            Scriptify
          </header>
        </Link>
        <button
          onClick={toggleMenu}
          className="bg-primary rounded-md p-2 hidden sm:block"
        >
          <RxHamburgerMenu className="text-3xl text-white" />
        </button>
        <div className="flex sm:hidden gap-x-12 md:gap-x-6 md:justify-between md:w-full items-center text-lg font-medium lg:gap-x-8">
          {navLinks.map((link) => (
            <Link
              key={link.title}
              to={link.url}
              className={`group transition-colors ease-in-out duration-300 relative ${
                location.pathname === link.url ? "text-primary" : "text-black"
              }`}
            >
              {link.title}
              <div
                className={`w-0 -translate-y-1 rounded transition-all ease-in-out duration-300 h-1 bg-primary ${
                  location.pathname === link.url
                    ? "w-full"
                    : "group-hover:w-full"
                }`}
              ></div>
            </Link>
          ))}
          <button
            onClick={() => dispatch(openRequestModal())}
            className="py-3 px-8 bg-primary hover:bg-primaryAccent transition-colors duration-200 text-white rounded-xl"
          >
            Doresc o consultație gratuită
          </button>
        </div>
      </nav>
      <Outlet />
      <div className="mt-20"></div>
      <footer className="bg-secondary grid gap-y-6 sm:grid-cols-1 grid-cols-2 pt-10 pb-2 px-80 xl:px-48 lg:px-24 md:px-12 sm:px-8 text-white mt-auto">
        <div className="flex flex-col text-left gap-y-2">
          <h3 className="text-4xl font-semibold">Scriptify</h3>
          <p>
            Scriptify este o agenție de web design, specializată în crearea de
            site-uri web și aplicații mobile. Oferim servicii de înaltă calitate
            și soluții personalizate pentru clienții noștri.
          </p>
        </div>
        <div className="flex flex-col text-right sm:text-left">
          <h3 className="text-xl font-semibold">Contactați-ne</h3>
          <p className="mt-2">Telefon: 079 000 000</p>
          <p>E-mail: office@scriptify.md</p>
        </div>
        <div className="col-span-2 mt-8 sm:mt-4 font-medium sm:col-span-1">
          <p>
            &copy; {new Date().getFullYear()} Scriptify. Toate drepturile
            rezervate.
          </p>
        </div>
      </footer>

      {modals.requestModal && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isModalClosing ? 0 : 1 }}
            className="fixed top-0 left-0 w-full h-full bg-black/25"
            onClick={closeModal}
          ></motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.9, x: "-50%", y: "-50%" }}
            animate={{
              opacity: isModalClosing ? 0 : 1,
              scale: isModalClosing ? 0.9 : 1,
            }}
            className="drop-shadow-xl bg-background rounded-lg w-1/3 lg:w-1/2 md:w-2/3 sm:w-full p-8 fixed top-1/2 left-1/2"
          >
            <button
              onClick={closeModal}
              className="absolute top-4 w-fit right-4 p-1.5 bg-surface rounded-full"
            >
              <IoMdClose className={"text-2xl"} />
            </button>
            {isConsultationSent ? (
              <div className="flex flex-col gap-y-4">
                <div className="bg-surface h-fit p-2 w-fit rounded-full">
                  <IoIosCheckmarkCircleOutline className="text-5xl text-primary" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-xl font-semibold">
                    Cererea a fost trimisă
                  </h2>
                  <p>
                    Cererea a fost trimisă cu succes. Unul dintre specialiștii
                    noștri te va contacta în cel mai scurt timp posibil.
                  </p>
                </div>
              </div>
            ) : (
              <form
                onSubmit={sendConsultation}
                className="mt-4 flex flex-col gap-y-3"
              >
                <h2 className="text-2xl sm:mt-6 font-bold">
                  Primeste o consultatie gratuită
                </h2>
                <p className="mt-2">
                  Completează formularul de mai jos și unul dintre specialiștii
                  noștri te va contacta în cel mai scurt timp posibil.
                </p>
                <div className="grid grid-cols-2 gap-x-8">
                  {["website", "application"].map((service) => (
                    <motion.button
                      key={service}
                      disabled={isConsultationSending || isConsultationSent}
                      type="button"
                      onClick={() => setSelectedService(service)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      initial={{ opacity: 0, y: 30 }}
                      animate={{
                        backgroundColor:
                          selectedService === service ? "#D6D6DE" : "#ffffff",
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{ duration: 0.3 }}
                      className="flex flex-col items-center gap-y-2 p-4 rounded-xl"
                    >
                      <img
                        src={service === "website" ? website : application}
                        alt={
                          service === "website" ? "Website" : "Aplicație mobilă"
                        }
                        className="w-20 h-20"
                      />
                      <span className="text-lg sm:text-base font-semibold">
                        {service === "website" ? "Website" : "Aplicație mobilă"}
                      </span>
                    </motion.button>
                  ))}
                </div>
                <input
                  disabled={isConsultationSending || isConsultationSent}
                  name="name"
                  placeholder="Nume complet"
                  required
                  className="p-2 border rounded"
                />
                <input
                  disabled={isConsultationSending || isConsultationSent}
                  name="phone_or_email"
                  placeholder="Număr de telefon sau email"
                  required
                  className="p-2 border rounded"
                />
                <motion.button
                  disabled={isConsultationSending || isConsultationSent}
                  whileHover={{ scale: 1.025 }}
                  whileTap={{ scale: 0.975 }}
                  initial={{ opacity: 0, x: -30 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="py-2 px-8 bg-primary hover:bg-primaryAccent mt-2 text-lg font-bold transition-colors duration-200 text-white rounded-xl"
                >
                  {isConsultationSending
                    ? "Se trimite..."
                    : "Trimiteți cererea"}
                </motion.button>
              </form>
            )}
          </motion.div>
        </>
      )}

      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: isMenuOpen ? 0 : "-100%" }}
        transition={{ duration: 0.25 }}
        className="fixed top-0 left-0 w-full h-full bg-background flex flex-col gap-y-8 items-center justify-center z-50"
      >
        <button
          onClick={() => setIsMenuOpen(false)}
          className="absolute top-5 right-5 p-2 bg-surface rounded-full"
        >
          <IoMdClose className={"text-3xl"} />
        </button>
        {navLinks.map((link) => (
          <Link
            key={link.title}
            to={link.url}
            onClick={() => setIsMenuOpen(false)}
            className="text-2xl font-semibold text-black transition-colors ease-in-out duration-200"
          >
            {link.title}
          </Link>
        ))}
        <button
          onClick={() => {
            setIsMenuOpen(false);
            dispatch(openRequestModal());
          }}
          className="py-3 px-8 bg-primary hover:bg-primaryAccent transition-colors duration-200 text-white rounded-xl"
        >
          Doresc o consultație gratuită
        </button>
      </motion.div>
    </div>
  );
};

export default UserLayout;
