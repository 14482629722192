import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestModal: false,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openRequestModal: (state) => {
      state.requestModal = true;
    },
    closeRequestModal: (state) => {
      state.requestModal = false;
    },
  },
});

export const { openRequestModal, closeRequestModal } = modalsSlice.actions;

export default modalsSlice.reducer;
