import React, { useState } from "react";
import personLaptop from "../static/images/person-laptop.svg";
import personPaper from "../static/images/person-paper.svg";
import phoneHands from "../static/images/phone-hands.svg";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { motion } from "framer-motion";
import { Typewriter } from "react-simple-typewriter";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { openRequestModal } from "../redux/modalsSlice";
import {
  WhyUsWebsites,
  websiteFeatures,
  faqs,
  webPrices,
  appPrices,
} from "../constants";

const Home = () => {
  const isLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTablet = useMediaQuery({ query: "(min-width: 1024px)" });
  const dispatch = useDispatch();
  const [isWebsiteFeaturesExpanded, setIsWebsiteFeaturesExpanded] =
    useState(false);
  const [faqsType, setFaqsType] = useState("website");
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div>
      <section className="px-80 gap-x-10 flex mt-36 md:mt-24 sm:mt-16 xl:px-48 lg:px-24 md:px-12 sm:px-8 sm:flex-col md:gap-y-6 sm:gap-y-16 md:flex-col">
        <div className="w-1/2 md:w-full lg:-translate-y-8 sm:w-full">
          <h1 className="text-5xl font-bold md:text-4xl sm:text-4xl">
            Servicii de creare Website și Aplicații Mobile În Moldova
          </h1>
          <p className="text-lg mt-4">
            Un site web bine conceput este esențial pentru brand, creând o
            impresie pozitivă și întărind încrederea clienților. O aplicație
            mobilă de succes poate crește semnificativ veniturile și baza de
            clienți.
          </p>
          <motion.button
            onClick={() => dispatch(openRequestModal())}
            whileHover={{ scale: 1.025 }}
            whileTap={{ scale: 0.975 }}
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            className="py-3 px-8 bg-primary hover:bg-primaryAccent mt-4 text-xl font-bold transition-colors duration-200 text-white rounded-xl"
          >
            Doresc o cunsultație gratuită
          </motion.button>
          <p className="mt-4">
            sau sunați la{" "}
            <span className="font-bold">
              <Typewriter typeSpeed={50} words={["079 000 000"]} />
            </span>
          </p>
        </div>
        <div className="w-1/2 relative md:ml-auto md:w-3/5 sm:w-full">
          <motion.div
            initial={{
              height: isLaptop ? "32rem" : isTablet ? "28rem" : "20rem",
            }}
            animate={{
              height: isLaptop ? "37rem" : isTablet ? "33rem" : "23rem",
            }}
            transition={{ duration: 0.5 }}
            className="rounded-t-full absolute min-h-fit left-0 bottom-0 bg-secondary/15 w-full"
          ></motion.div>
          {!isImageLoaded && <div className="h-[38.75rem]"></div>}
          <motion.img
            initial={{ x: 30, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            src={personLaptop}
            className="w-full z-10"
            alt="Person with laptop"
            onLoad={() => setIsImageLoaded(true)}
          />
        </div>
      </section>

      <motion.hr
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        className="mx-auto h-2 -translate-y-2 bg-secondary"
      />
      <section className="px-80 mt-20 sm:mt-16 xl:px-48 lg:px-24 md:px-12 sm:px-8">
        <h2>De ce ar trebui să comandati crearea web site-ului la noi?</h2>
        <p className="subtitle mt-2">
          Suntem o echipă de specialiști cu experiență în domeniul creării de
          site-uri web Ne străduim să oferim cele mai bune soluții pentru
          clienții noștri, astfel încât să obțină rezultatele dorite.
        </p>
        <div className="grid grid-cols-3 mt-6 gap-6 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          {WhyUsWebsites.map((item, index) => (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              viewport={{ once: true }}
              key={index}
              className="flex gap-x-6 p-4 rounded-lg drop-shadow items-center md:flex-col sm:flex-col gap-y-4 xl:flex-col bg-white"
            >
              <div className="p-4 bg-surface rounded-full">{item.icon}</div>
              <div className="flex flex-col gap-y-1">
                <h3 className="text-xl font-semibold">{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
      <section className="px-80 mt-20 sm:mt-16 xl:px-48 lg:px-24 md:px-12 sm:px-8">
        <h2>Iată prețurile noastre pentru crearea site-urilor web</h2>
        <p className="subtitle mt-2">
          Oferim pachete de prețuri pentru toate nevoile, de la site-uri web
          simple la site-uri web complexe. Contactați-ne pentru a afla mai multe
          și pentru a primi o ofertă personalizată.
        </p>
        <div className="flex md:flex-col sm:flex-col mt-6 gap-x-12 gap-y-6">
          <div className="w-1/2 md:w-full sm:w-full">
            <div className="grid grid-cols-2 lg:grid-cols-1 sm:grid-cols-1 gap-6 mt-6">
              {webPrices.map((item, index) => (
                <motion.div
                  initial={{ opacity: 0, x: -30 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  key={index}
                  className="flex flex-col gap-y-2 p-4 rounded-lg drop-shadow bg-white"
                >
                  <h3 className="text-xl font-semibold">{item.title}</h3>
                  <p>{item.description}</p>
                  <p className="mt-auto">
                    Preț: <span className="font-semibold">{item.price}</span>
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
          <div className="w-1/2 relative md:mx-auto sm:w-full">
            <motion.img
              initial={{ x: 30, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              src={personPaper}
              className="w-full mt-8 sm:mt-4"
              alt="Person with paper"
            />
          </div>
        </div>
      </section>
      <section className="px-80 mt-20 xl:px-48 lg:px-24 md:px-12 sm:px-8 sm:mt-16">
        <h2>Funcționalitățile pe care le putem adăuga site-ului dvs.</h2>
        <p className="subtitle mt-2">
          Oferim o gamă largă de funcționalități pentru site-urile web pe care
          le creăm, de la formulare de contact la magazine online și multe
          altele.
        </p>
        <div className="grid grid-cols-3 mt-6 gap-6 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          {websiteFeatures.slice(0, 6).map((item, index) => (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              viewport={{ once: true }}
              key={index}
              className="flex gap-x-6 p-4 md:flex-col gap-y-4 sm:flex-col rounded-lg drop-shadow items-center bg-white"
            >
              <div className="p-4 bg-surface rounded-full">{item.icon}</div>
              <div className="flex flex-col gap-y-1">
                <h3 className="text-xl font-semibold">{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
        {isWebsiteFeaturesExpanded && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.3 }}
            className="grid grid-cols-3 gap-6 overflow-hidden mt-4 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1"
          >
            {websiteFeatures.slice(6).map((item, index) => (
              <div
                key={index}
                className="flex gap-x-6 md:flex-col gap-y-4 sm:flex-col p-4 rounded-lg drop-shadow items-center bg-white"
              >
                <div className="p-4 bg-surface rounded-full">{item.icon}</div>
                <div className="flex flex-col gap-y-1">
                  <h3 className="text-xl font-semibold">{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </motion.div>
        )}
        <motion.button
          onClick={() =>
            setIsWebsiteFeaturesExpanded(!isWebsiteFeaturesExpanded)
          }
          initial={{ opacity: 0 }}
          animate={{
            opacity: isWebsiteFeaturesExpanded ? 0 : 1,
            height: isWebsiteFeaturesExpanded ? 0 : "auto",
          }}
          transition={{ duration: 0.2 }}
          className="relative overflow-hidden w-full mt-4"
        >
          <motion.div
            initial={{ width: 0, left: "50%" }}
            whileInView={{ width: "100%", left: 0 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            viewport={{ once: true }}
            className="rounded mx-auto h-1.5 bg-secondary top-1/2 -z-10 -translate-y-1/2 absolute"
          ></motion.div>
          <motion.div
            initial={{ scale: 0.5, rotate: 180, opacity: 0 }}
            whileInView={{ scale: 1, rotate: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.3, delay: 0.1 }}
          >
            <MdOutlineKeyboardArrowDown className="bg-secondary text-white z-10 text-3xl mx-auto rounded-full" />
          </motion.div>
        </motion.button>
      </section>
      <section className="px-80 mt-16 xl:px-48 lg:px-24 md:px-12 sm:px-8 sm:mt-16">
        <h2>Doriți o aplicatie mobilă pentru afacerea dvs.?</h2>
        <p className="subtitle mt-2">
          O aplicație mobilă de succes poate crește semnificativ veniturile și
          baza de clienți. Oferim servicii de dezvoltare de aplicații mobile
          pentru toate tipurile de afaceri.
        </p>
        <div className="flex mt-6 gap-x-12 md:flex-col sm:flex-col gap-y-6">
          <div className="w-1/2 md:w-full sm:w-full">
            <div className="grid grid-cols-2 lg:grid-cols-1 gap-6 mt-6 sm:grid-cols-1">
              {appPrices.map((item, index) => (
                <div className="flex flex-col gap-y-2 p-4 rounded-lg drop-shadow bg-white">
                  <h3 className="text-xl font-semibold">{item.title}</h3>
                  <p>{item.description}</p>
                  <p className="mt-auto">
                    Preț: <span className="font-semibold">{item.price}</span>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-1/2 md:mx-auto sm:w-full">
            <motion.img
              initial={{ x: 30, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
              src={phoneHands}
              className="w-full mt-8"
              alt="Person with phone"
            />
          </div>
        </div>
      </section>
      <section className="px-80 mt-20 xl:px-48 lg:px-24 md:px-12 sm:px-8 sm:mt-16">
        <h2>Întrebări frecvente</h2>
        <p className="subtitle mt-2">
          Avem răspunsuri la cele mai frecvente întrebări despre serviciile de
          creare site-uri web și aplicații mobile.
        </p>
        <div className="grid grid-cols-3 mt-4 md:grid-cols-2 sm:grid-cols-1">
          {faqs.map((item, index) => (
            <motion.button
              key={index}
              initial={{ opacity: 0, y: -30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: index * 0.1 }}
              onClick={() => setFaqsType(item.type)}
              className={
                " py-2 font-semibold border-r-2 border-l-2 border-l-primary border-solid border-r-primary  transition-colors ease-in-out duration-200 " +
                (faqsType === item.type
                  ? "bg-secondary text-white "
                  : "bg-secondary/15 hover:bg-secondary/20 ") +
                (index === faqs.length - 1 ? "md:col-span-2" : "")
              }
            >
              {item.title}
            </motion.button>
          ))}
        </div>
        <div className="mt-6">
          {faqs
            .find((faq) => faq.type === faqsType)
            .questions.slice(0, 4)
            .map((item, index) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                key={index}
                className="mt-4"
              >
                <h3 className="text-xl font-semibold">{item.question}</h3>
                <p>{item.answer}</p>
              </motion.div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
