import { RiSeoLine } from "react-icons/ri";
import { AiTwotoneTool } from "react-icons/ai";
import { GoServer } from "react-icons/go";
import { MdOutlineReport } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import { FaWpforms } from "react-icons/fa6";
import { MdOutlineStorefront } from "react-icons/md";
import { RiBloggerLine } from "react-icons/ri";
import { FaPhotoFilm } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import { GrSecure } from "react-icons/gr";
import { MdAnimation } from "react-icons/md";
import { MdOutlineDesignServices } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { MdOutlineLocalPhone, MdMailOutline } from "react-icons/md";
import { FaRegClock } from "react-icons/fa6";

const debug = false;
const base_url = debug ? "http://localhost:8000" : "";
export const api_url = base_url + "/api";
export const media_url = base_url + (debug ? "" : "/media");

export const WhyUsWebsites = [
  {
    icon: <MdOutlineDesignServices className="text-2xl text-primary" />,
    title: "Web Design creativ",
    description:
      "Fiecare site web este unic și creat conform cerințelor clientului. Designul este realizat de designerii noștri profesioniști.",
  },
  {
    icon: <CgWebsite className="text-2xl text-primary" />,
    title: "Site-uri web responsive",
    description:
      "Site-urile web create de noi sunt optimizate pentru toate dispozitivele, de la smartphone-uri la computere desktop.",
  },
  {
    icon: <RiSeoLine className="text-2xl text-primary" />,
    title: "SEO optimizat",
    description:
      "Site-urile web create de noi sunt optimizate pentru motoarele de căutare, ceea ce crește șansele de a fi găsite de clienți.",
  },
  {
    icon: <AiTwotoneTool className="text-2xl text-primary" />,
    title: "Suport tehnic",
    description:
      "Oferim suport tehnic pentru site-urile web create de noi, astfel încât să puteți fi siguri că site-ul dvs. este întotdeauna funcțional.",
  },
  {
    icon: <GoServer className="text-2xl text-primary" />,
    title: "Găzduire sigură",
    description:
      "Oferim găzduire sigură pentru site-urile web create de noi, astfel încât să puteți fi siguri că datele dvs. sunt în siguranță.",
  },
  {
    icon: <MdOutlineReport className="text-2xl text-primary" />,
    title: "Raportare",
    description:
      "Oferim rapoarte periodice despre performanța site-ului web, astfel încât să puteți vedea cum funcționează site-ul dvs. și să faceți îmbunătățiri.",
  },
];

export const websiteFeatures = [
  {
    icon: <MdOutlinePayment className="text-2xl text-primary" />,
    title: "Modalități de plată",
    description:
      "Putem implementa diferite modalități de plată: MAIB, Paypal, mPay, MoneyCollect, Stripe, etc.",
  },
  {
    icon: <FaWpforms className="text-2xl text-primary" />,
    title: "Formulare de contact",
    description:
      "Putem adăuga formulare de contact pe site-ul dvs. pentru a facilita comunicarea cu clienții.",
  },
  {
    icon: <MdOutlineStorefront className="text-2xl text-primary" />,
    title: "Magazin online",
    description:
      "Putem crea un magazin online pe site-ul dvs. pentru a vinde produse și servicii online.",
  },
  {
    icon: <RiBloggerLine className="text-2xl text-primary" />,
    title: "Blog",
    description:
      "Putem adăuga un blog pe site-ul dvs. pentru a publica articole și știri despre afacerea dvs.",
  },
  {
    icon: <FaPhotoFilm className="text-2xl text-primary" />,
    title: "Galerie foto/video",
    description:
      "Putem adăuga o galerie foto sau video pe site-ul dvs. pentru a afișa produsele sau serviciile dvs.",
  },
  {
    icon: <IoShareSocialOutline className="text-2xl text-primary" />,
    title: "Integrare cu rețelele sociale",
    description:
      "Putem integra site-ul dvs. cu rețelele sociale pentru a vă ajuta să vă promovați afacerea.",
  },
  {
    icon: <GrSecure className="text-2xl text-primary" />,
    title: "Certificat SSL",
    description:
      "Putem instala un certificat SSL pe site-ul dvs. pentru a asigura datele clienților dvs.",
  },
  {
    icon: <MdAnimation className="text-2xl text-primary" />,
    title: "Animatii",
    description:
      "Putem adăuga animații pe site-ul dvs. pentru a-l face mai interactiv și atractiv pentru vizitatori.",
  },
];

export const faqs = [
  {
    type: "website",
    title: "Întrebări despre site-uri web",
    questions: [
      {
        question: "Cum pot comanda un site web?",
        answer:
          "Pentru a comanda un site web, trebuie să ne contactați prin telefon sau prin trimiterea formularului 'Doresc o consultație gratuită' și să ne spuneți ce tip de site web doriți. Vom discuta cerințele dvs. și vă vom oferi o ofertă personalizată.",
      },
      {
        question: "Cât durează crearea unui site web?",
        answer:
          "Durata creării unui site web depinde de complexitatea site-ului. Site-urile web simple pot fi create în câteva zile, în timp ce site-urile web complexe pot dura săptămâni sau luni.",
      },
      {
        question: "Cum pot adăuga nou funcționalități la site-ul meu web?",
        answer:
          "Pentru a adăuga noi funcționalități la site-ul dvs. web, trebuie să ne contactați și să ne spuneți ce funcționalități doriți să adăugăm. Vom discuta cerințele dvs. și vă vom oferi o ofertă personalizată.",
      },
      {
        question:
          "Cum pot vedea rapoartele despre performanța site-ului meu web?",
        answer:
          "Pentru a vedea rapoartele despre performanța site-ului dvs. web, trebuie să ne contactați și să ne solicitati rapoartele. Vă vom trimite rapoartele periodice despre performanța site-ului dvs.",
      },
      {
        question: "Ce tehologii folosiți pentru crearea site-urilor web?",
        answer:
          "Folosim cele mai recente tehnologii web pentru a crea site-uri web moderne și funcționale. Folosim HTML, CSS, JavaScript, React, Python, SQL, etc.",
      },
      {
        question: "Cum pot actualiza site-ul meu web?",
        answer:
          "Pentru a actualiza site-ul dvs. web, trebuie să ne contactați și să ne spuneți ce modificări doriți să facem. Vom face modificările și vă vom informa când site-ul dvs. este actualizat.",
      },
    ],
  },
  {
    type: "mobile",
    title: "Întrebări despre aplicații mobile",
    questions: [
      {
        question: "Cum pot comanda o aplicație mobilă?",
        answer:
          "Pentru a comanda o aplicație mobilă, trebuie să ne contactați și să ne spuneți ce tip de aplicație mobilă doriți. Vom discuta cerințele dvs. și vă vom oferi o ofertă personalizată.",
      },
      {
        question: "Cât durează crearea unei aplicații mobile?",
        answer:
          "Durata creării unei aplicații mobile depinde de complexitatea aplicației. Aplicațiile mobile simple pot fi create în câteva săptămâni, în timp ce aplicațiile mobile complexe pot dura luni.",
      },
      {
        question:
          "Aplicația mea mobilă va fi disponibilă pe App Store și Google Play?",
        answer:
          "Da, putem înscrie aplicația dvs. mobilă pe App Store și Google Play pentru a fi descărcată de utilizatori. Folosim tehnologii pentru a face aplicația compatibilă cu aceste platforme.",
      },
      {
        question: "Ce tehnologii folosiți pentru crearea aplicațiilor mobile?",
        answer:
          "Folosim cele mai recente tehnologii mobile pentru a crea aplicații mobile moderne și funcționale. Folosim Flutter, Python, SQL, etc.",
      },
      {
        question:
          "Cât timp durează până când aplicația mea mobilă este aprobată?",
        answer:
          "Procesul de aprobare a aplicației mobile pe App Store și Google Play poate dura câteva zile sau săptămâni, în funcție de complexitatea aplicației și de cerințele platformelor.",
      },
      {
        question: "Cum pot actualiza aplicația mea mobilă?",
        answer:
          "Pentru a actualiza aplicația dvs. mobilă, trebuie să ne contactați și să ne spuneți ce modificări doriți să facem. Vom face modificările și vă vom informa când aplicația dvs. este actualizată.",
      },
      {
        question: "Cum pot adăuga noi funcționalități la aplicația mea mobilă?",
        answer:
          "Pentru a adăuga noi funcționalități la aplicația dvs. mobilă, trebuie să ne contactați și să ne spuneți ce funcționalități doriți să adăugăm. Vom discuta cerințele dvs. și vă vom oferi o ofertă personalizată.",
      },
    ],
  },
  {
    type: "other",
    title: "Alte întrebări",
    questions: [
      {
        question: "Care sunt modalitățile de plată acceptate?",
        answer: "Acceptăm plăți prin transfer bancar, card și PayPal.",
      },
      {
        question: "Oferiți suport tehnic?",
        answer:
          "Da, oferim suport tehnic pentru site-urile web și aplicațiile mobile create de noi.",
      },
      {
        question: "Cum pot contacta echipa dvs.?",
        answer:
          "Puteți să ne contactați prin telefon, e-mail sau completând formularul de contact de pe site-ul nostru.",
      },

      {
        question: "Ce garanții oferiți?",
        answer:
          "Oferim garanții pentru site-urile web și aplicațiile mobile create de noi. Dacă întâmpinați probleme cu site-ul sau aplicația dvs., vă rugăm să ne contactați și vom remedia problema cât mai curând posibil.",
      },
    ],
  },
];

export const webPrices = [
  {
    title: "Înregistrarea domeniului",
    description:
      "Se plătește doar prețul domeniului, care variază în funcție de extensie",
    price: "Gratuit",
  },
  {
    title: "Găzduirea site-ului web",
    description:
      "Găzduim site-ul pe servere rapide și sigure, adaptate traficului și spațiului de stocare necesar.",
    price: "100 MDL - 600 MDL",
  },
  {
    title: "Optimizare pentru motoarele de căutare (SEO)",
    description:
      "Optimizăm site-ul web pentru motoarele de căutare, astfel încât să fie ușor de găsit de clienți.",
    price: "Inclus în preț",
  },
  {
    title: "Site web basic",
    description:
      "Site web simplu, cu un design simplu și funcționalități de bază. Perfect pentru afaceri mici.",
    price: "100€",
  },
  {
    title: "Site web standard",
    description:
      "Site web cu un design mai complex și funcționalități suplimentare. Perfect pentru orice afacere.",
    price: "300€",
  },
  {
    title: "Site web premium",
    description:
      "Site web cu un design personalizat și funcționalități avansate. Perfect pentru afaceri mari și complexe.",
    price: "600€",
  },
];

export const appPrices = [
  {
    title: "Găzduirea aplicației mobile",
    description:
      "Găzduim aplicația mobilă pe servere rapide și sigure, astfel încât să funcționeze întotdeauna corect.",
    price: "100 MDL - 600 MDL",
  },
  {
    title: "Înscrierea aplicației în App Store și Google Play",
    description: "Veți plăti doar taxele de înscriere ale acestor platforme.",
    price: "Gratuită",
  },
  {
    title: "Aplicație mobilă simplă",
    description:
      "Aplicație mobilă simplă, ce oferă funcționalități de bază. Perfectă pentru concepte noi și afaceri mici.",
    price: "100€",
  },
  {
    title: "Aplicație mobilă standard",
    description:
      "Aplicație cu un design mai complex și funcționalități suplimentare. Perfect pentru orice afacere.",
    price: "400€",
  },
  {
    title: "Aplicație mobilă premium",
    description:
      "Aplicație cu funcționalități avansate și un design atent lucrat. Perfect pentru idei inovatoare.",
    price: "1200€",
  },
];

export const contactCards = [
  {
    icon: <MdOutlineLocalPhone className="text-3xl text-primary" />,
    title: "Telefon",
    description: "079 000 000",
  },
  {
    icon: <MdMailOutline className="text-3xl text-primary" />,
    title: "E-mail",
    description: "office@scriptify.md",
  },
  {
    icon: <FaRegClock className="text-3xl text-primary" />,
    title: "Program",
    description: "Luni - Vineri, 09:00 - 18:00",
  },
];
